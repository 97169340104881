@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'NB International Pro';
  src:
    url('../assets/fonts/nb-international-pro/nb-international-pro-regular.woff2')
      format('woff2'),
    url('../assets/fonts/nb-international-pro/nb-international-pro-regular.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NB International Pro';
  src:
    url('../assets/fonts/nb-international-pro/nb-international-pro-bold.woff2')
      format('woff2'),
    url('../assets/fonts/nb-international-pro/nb-international-pro-bold.woff')
      format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'NB International Pro';
  src:
    url('../assets/fonts/nb-international-pro/nb-international-pro-light.woff2')
      format('woff2'),
    url('../assets/fonts/nb-international-pro/nb-international-pro-light.woff')
      format('woff');
  font-weight: light;
}

@font-face {
  font-family: 'NB International Mono';
  src:
    url('../assets/fonts/nb-international-pro/nb-international-mono.woff2')
      format('woff2'),
    url('../assets/fonts/nb-international-pro/nb-international-mono.woff')
      format('woff');
  font-weight: normal;
}

.bars-icon.active .bar:first-child {
  transform: translateY(3.75px) rotate(45deg);
}

.bars-icon.active .bar:last-child {
  transform: translateY(-3.75px) rotate(-45deg);
}

.svg-white path {
  fill: 'white';
}

* {
  font-display: optional;
  /* cursor:
    url('../assets/images/cursor.svg') 36 36,
    auto; */
}

.bg-polka {
  opacity: 1;
  background-image: radial-gradient(
    rgba(0, 0, 0, 1) 0.5px,
    rgba(0, 0, 0, 0.5) 0.05px
  );
  background-size: 10px 10px;
}

.bg-polka-transparent {
  opacity: 1;
  background-image: radial-gradient(
    rgba(0, 0, 0, 0.5) 0.5px,
    rgba(0, 0, 0, 0) 0.05px
  );
  background-size: 10px 10px;
}

.bg-forth {
  background-position: 15%;
}
